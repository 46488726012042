
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElNotification, ElInput, ElSelect, ElOption, ElButton} from 'element-plus';
import {App} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {userCard} from '@/services/server/web/bank';
import {ICard, ICardListResult, IBankCard, IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/web/phone-verification';
import TelInput from '@/common/web/telInput/index.vue';

@Options({
  components: {
    TelInput,
    ElInput,
    ElSelect,
    ElOption,
    ElButton
  },
  methods: {
    checkInputs() {
      if (
        ['¥', '₫'].includes(this.config.dollarSign) &&
        !/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.name)
      ) {
        return false;
      }
      if (
        !['¥', '₫'].includes(this.config.dollarSign) &&
        (!/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.lastName) ||
          !/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.firstName))
      ) {
        return false;
      }
      if (!/\d{8,19}/.test(this.info.number)) {
        this.info.numberErrorMsg = 'ui_hint_acc_length8';
        return false;
      }
      // if (!this.userInfo?.phoneNumber.verification && !this.phoneNumber) {
      //   this.info.phoneNumberMsg = 'ui_phone_not_entered';
      //   return false;
      // }
      // if (!this.info.phoneCode) {
      //   this.info.phoneCodeMsg = 'tip_code_blank';
      //   return false;
      // }
      return true;
    },
    async onSubmit() {
      if (!this.checkInputs()) return;
      // this.loading = true
      // if (this.userInfo?.phoneNumber.verification) {
      /* eslint-disable-next-line */
      await this.addCard({phone_number_code: this.info.phoneCode});
      // }
      // else {
      //   const data = await this.bindSMS();
      //   if (!(data instanceof Error)) {
      //     /* eslint-disable-next-line */
      //     await this.addCard({phone_number_code: this.info.phoneCode});
      //   }
      // }
    },
    beforeCheckSms() {
      // console.log(this.$info.$_authMode, this.$_phoneNumber, this.)
      if (!this.userInfo.phoneNumber.verification && !this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
        return false;
      } else {
        this.checkSMS();
      }
    },

    async checkSMS() {
      if (this.userInfo?.phoneNumber.verification) {
        this.showWaitTime();
        //已認證
        const data = await phoneVerification.read<IMessage>({});
        if (data instanceof Error) {
          ElMessage({
            type: 'error',
            message: data.message || 'fail'
          });
        } else {
          ElMessage({
            type: 'success',
            message: data.message || 'success'
          });
        }
        return data;
      } else {
        //未認證
        const data = await this.addSMS();
        return data;
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          console.log('response: ', response);
          // this.$ElMessage.success(info.message)
          ElMessage({
            type: 'success',
            message: response?.message || 'success'
          });
          // this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$ElMessage.fail(error.message)
          ElMessage({
            type: 'error',
            message: error.message || 'fail'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },

    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    verSms() {
      return this.info.phoneDisabled;
    },
    phoneNumber() {
      return this.$refs.tel ? this.$refs.tel.info.phoneObject.number.e164 : '';
    }
  }
})
export default class AddBankCard extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  cardList: ICard[] = [];
  control: {
    showBanks: boolean;
    columns: IBankCard[];
    bankId: string;
    bankCode: string;
  } = {
    showBanks: false,
    columns: [],
    bankId: '', // 銀行 ID
    bankCode: ''
  };
  info = {
    phoneNumber: '',
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: '',
    phoneCodeMsg: '',
    number: '',
    lastName: '',
    firstName: '',
    name: '',
    bankId: '',
    numberErrorMsg: ''
  };
  errors = {
    name: false,
    lastName: false,
    firstName: false,
    number: false,
    phoneNumber: false,
    phoneCode: false,
    bank: false
  };
  get stopValid() {
    switch (this.config.dollarSign) {
      case '₫':
      case '¥':
        return !this.info.number || !this.info.bankId || !this.info.name;
      default:
        return (
          // !this.info.number || !this.info.bankId
          !this.info.number || !this.info.bankId || !this.info.lastName || !this.info.firstName
        );
    }
  }
  async mounted() {
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    const [res1, res2] = await Promise.all([
      userCard.info<ICardListResult>(),
      userCard.infoSettings({name: ['user_binding_card_time']})
    ]);
    console.log('res1, res2: ', res1, res2);
    if (!(res1 instanceof Error)) {
      const list = res1.list.map(v => {
        v.text = `${v.bankName}`;
        v.dom = `<span class="icon bank-img bank__icon-${v.bankCode}"></span><span>${v.bankName}</span>`;
        return v;
      });
      this.control.columns = [...this.control.columns, ...list];
    }
  }
  handeValidate(val: {isValid: boolean}) {
    this.errors.phoneNumber = !val.isValid;
  }
  checkRules(value: any, key: string) {
    switch (key) {
      case 'name':
        this.errors.name = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'lastName':
        this.errors.lastName = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'firstName':
        this.errors.firstName = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'number':
        this.errors.number = !value || !/^\d{8,19}$/.test(value);
        break;
      case 'phoneCode':
        this.errors.phoneCode = !value;
        break;
    }
  }
  clearError() {
    console.log('clearError');
  }

  async addCard() {
    const {name, ...other} = this.info;
    const {dollarSign} = this.config;
    const bank = this.control.columns.filter(v => v.bankId === other.bankId)[0];
    const para = {
      /* eslint-disable-next-line */
      bank_id: bank.bankId, // 銀行 ID
      /* eslint-disable-next-line */
      bank_code: bank.bankCode, // 銀行代碼
      province: '', // 省份 ID
      city: '', // 城市 ID
      branch: '', // 開戶支行
      number: other.number, // 銀行帳號
      /* eslint-disable-next-line */
      confirm_number: other.number
    };
    let params = {};

    if (dollarSign === '₫') {
      params = {...params, name};
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, first_name: other.firstName, last_name: other.lastName};
    }
    params = {...para, ...params};
    console.log(params);
    const data = await this.bindCard(params);
    console.log(`debug: onSubmit -> data`, data);
    if (data instanceof Error) {
      ElMessage.error(data.message || 'fail');
      return false;
    } else {
      ElNotification({
        message: this.$t('tip_add_success')
      });
      this.info = {
        phoneNumber: '',
        phoneNumberMsg: '',
        phoneDisabled: true,
        phoneWaitTime: 300,
        phoneErrorWaitTime: 15,
        verPhoneWaitTime: 0,
        smsInterval: '',
        phoneCode: '',
        phone: '',
        phoneCodeMsg: '',
        number: '',
        lastName: '',
        firstName: '',
        name: '',
        bankId: '',
        numberErrorMsg: ''
      };
      setTimeout(() => {
        this.$router.back();
      }, 1000);
    }
    return data;
  }
  async bindSMS() {
    // let number = phoneUtil.parseAndKeepRawInput(this.$info.$_phone, this.$info.$_countryCode)
    const data = await phoneVerification.updated({
      // phone_number: phoneUtil.format(number, PNF.E164),
      code: this.info.phoneCode
    });
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message:
          this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail'
      });
      // console.log('info.message', info.message)
      // let message = this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed')
      // this.$ElMessage.fail(message || 'fail')
      return false;
    } else {
      ElMessage({
        type: 'error',
        message:
          this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
          'fail'
      });
      //修改狀態
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    return data;
  }
  async bindCard(params: object) {
    const data = await userCard.created(params);
    return data;
  }
  goToLink(name: string) {
    this.$router.push({name});
  }
}
