<template>
  <div class="password-container">
    <div class="static-container-header ">
      <h5 class="static-container-header__title u_t-transfrom-cap">
        <div
          class="static-container-header__left u_c--pointer"
          @click="goToLink('personalInformation')"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M23.9730573,7.39798482 L13.4139164,17.3532383 C13.0286944,17.7164292 12.4271498,17.7164292 12.0419277,17.3532383 L1.48278682,7.39798482 C1.36223296,7.28432556 1.35664379,7.09445831 1.47030306,6.97390446 C1.56111944,6.87757914 1.70433913,6.85216956 1.8227492,6.9113746 L12.2807085,12.1403542 C12.562236,12.281118 12.8936082,12.281118 13.1751357,12.1403542 L23.6330949,6.9113746 C23.7812885,6.83727783 23.9614904,6.89734513 24.0355872,7.04553868 C24.0947922,7.16394875 24.0693826,7.30716844 23.9730573,7.39798482 Z"
              id="矩形"
              fill="#333333"
              mask="url(#mask-2)"
              transform="translate(12.727922, 12.000000) rotate(-270.000000) translate(-12.727922, -12.000000) "
            ></path>
          </svg>
          <span>{{ $t('btn_add_bank_card') }}</span>
        </div>
        <div class="static-container-header__right"></div>
      </h5>
    </div>
    <div class="list-detail__form ">
      <div
        class="form__field inboder"
        :class="{note: errors.name}"
        v-if="config.dollarSign === '¥' || config.dollarSign === '₫'"
      >
        <div class="form__title">{{ $t('ui_open_acc_name_colon') }}</div>
        <div>
          <el-input
            class="c_input--reglong"
            :class="[!info.name ? ' noneinput' : 'hasinput']"
            type="text"
            name="account_name"
            v-model="info.name"
            :placeholder="'! ' + $t('ui_input_name')"
            @blur="checkRules(info.name, 'name')"
          ></el-input>
        </div>
      </div>
      <tempalte v-else>
        <div class="form__field inboder" :class="{note: errors.lastName}" v-if="nowLang === 'zh'">
          <div class="form__title">{{ $t('ui_last_name') }}：</div>
          <div>
            <el-input
              class="c_input--reglong"
              :class="[!info.lastName ? ' noneinput' : 'hasinput']"
              type="text"
              name="account_name"
              v-model="info.lastName"
              :placeholder="'! ' + $t('ui_input_name')"
              @blur="checkRules(info.lastName, 'lastName')"
            ></el-input>
          </div>
        </div>
        <div class="form__field inboder" :class="{note: errors.firstName}">
          <div class="form__title">{{ $t('ui_first_name') }}：</div>
          <div>
            <el-input
              class="c_input--reglong"
              :class="[!info.firstName ? ' noneinput' : 'hasinput']"
              type="text"
              name="account_name"
              v-model="info.firstName"
              :placeholder="'! ' + $t('ui_input_name')"
              @blur="checkRules(info.firstName, 'firstName')"
            ></el-input>
          </div>
        </div>
        <div class="form__field inboder" :class="{note: errors.lastName}" v-if="nowLang !== 'zh'">
          <div class="form__title">{{ $t('ui_last_name') }}：</div>
          <div>
            <el-input
              class="c_input--reglong"
              :class="[!info.lastName ? ' noneinput' : 'hasinput']"
              type="text"
              name="account_name"
              v-model="info.lastName"
              :placeholder="'! ' + $t('ui_input_name')"
              @blur="checkRules(info.lastName, 'lastName')"
            ></el-input>
          </div>
        </div>
      </tempalte>
      <div class="form__field inboder" :class="{note: errors.bank}">
        <div class="form__title">{{ $t('ui_open_acc_bank_colon') }}</div>
        <div>
          <el-select
            :class="[!info.bankId ? ' noneinput' : 'hasinput']"
            class="c_input--reglong"
            name="bank"
            v-model="info.bankId"
            :placeholder="'! ' + $t('ui_wap_text_105')"
            @blur="checkRules(info.bankId, 'bank')"
          >
            <el-option
              v-for="item in control.columns"
              :key="item.bankId"
              :value="item.bankId"
              :label="item.bankName"
            />
          </el-select>
        </div>
      </div>

      <div class="form__field inboder" :class="{note: errors.number}">
        <div class="form__title">{{ $t('ui_bank_acc_colon') }}</div>
        <div>
          <el-input
            class="c_input--reglong"
            :class="[!info.number ? ' noneinput' : 'hasinput']"
            type="text"
            name="account"
            v-model="info.number"
            :maxlength="19"
            :placeholder="'! ' + $t('ui_please_enter_the_bank_card_number')"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="checkRules(info.number, 'number')"
          ></el-input>
        </div>
        <div class="u_p--l10 j_txt--tip" v-show="info.number.length < 8">
          <i class="el-icon-warning-outline"></i>
          {{
            $t('ui_num_to_num', ['10', '12'])
              .replace('%n%', '10')
              .replace('%m%', '12')
          }}
        </div>
      </div>
      <!--phone-->
      <!-- <div class="form__field" :class="{note: errors.phoneNumber}">
        <div class="form__title">{{ $t('ui_mobile_phone_number') }}</div>
        <div class="u_p--l15" v-if="userInfo.phoneNumber.verification">
          {{ userInfo.phoneNumber.text }}
        </div>
        <tel-input
          v-else
          ref="tel"
          :value="info.phone"
          :preferredCountries="['VN', 'TH']"
          @validate="handeValidate"
          class="j_background--day-t5 telinput "
          @blur="checkRules(info.phone, 'phoneNumber')"
        ></tel-input>
        <div class="u_m--l10">
          <el-button
            round
            class=""
            icon=""
            :type="'info'"
            size="small"
            :hairline="true"
            @click="beforeCheckSms"
            v-text="(info.verPhoneWaitTime || $t('ui_send_verification')) + ''"
            :disabled="+info.verPhoneWaitTime > +0"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-button>
        </div>
      </div> -->

      <!--phone code-->
      <!-- <div class="form__field inboder" :class="{note: errors.phoneCode}">
        <div class="form__title">{{ $t('ui_sms_verification_code') }}</div>
        <el-input
          :class="[!info.phoneCode ? ' noneinput ' : ' hasinput']"
          class="c_input--reglong clearpadding"
          v-model="info.phoneCode"
          clearable
          type="text"
          :placeholder="'! ' + $t('ui_please_enter_phone_verification_code')"
          :error-message="$t('')"
          @input="clearError"
          oninput="value=value.replace(/[^\d]/g,'')"
          @blur="checkRules(info.phoneCode, 'phoneCode')"
        >
        </el-input>
      </div> -->
      <div class="flex-column flex text-color u_m--t20">
        <span class="c_tooltip j_txt--primary">*{{ $t('ui_hint_card3') }}</span>
        <span class="c_tooltip j_txt--primary">*{{ $t('sysmsg_bind_bank_limit_time') }}</span>
      </div>
      <div class="flex flex-justify-center u_t--center u_m--t50 u_m--b50">
        <button
          type="button"
          class="c_button--huge userbtn-bg--submit c_input--reglong"
          @click="onSubmit"
          :disabled="stopValid"
        >
          <span class="c_button__text">{{ $t('btn_submit') }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElNotification, ElInput, ElSelect, ElOption, ElButton} from 'element-plus';
import {App} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {userCard} from '@/services/server/web/bank';
import {ICard, ICardListResult, IBankCard, IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/web/phone-verification';
import TelInput from '@/common/web/telInput/index.vue';

@Options({
  components: {
    TelInput,
    ElInput,
    ElSelect,
    ElOption,
    ElButton
  },
  methods: {
    checkInputs() {
      if (
        ['¥', '₫'].includes(this.config.dollarSign) &&
        !/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.name)
      ) {
        return false;
      }
      if (
        !['¥', '₫'].includes(this.config.dollarSign) &&
        (!/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.lastName) ||
          !/^[^@#$%^&*()_+=]{1,20}$/.test(this.info.firstName))
      ) {
        return false;
      }
      if (!/\d{8,19}/.test(this.info.number)) {
        this.info.numberErrorMsg = 'ui_hint_acc_length8';
        return false;
      }
      // if (!this.userInfo?.phoneNumber.verification && !this.phoneNumber) {
      //   this.info.phoneNumberMsg = 'ui_phone_not_entered';
      //   return false;
      // }
      // if (!this.info.phoneCode) {
      //   this.info.phoneCodeMsg = 'tip_code_blank';
      //   return false;
      // }
      return true;
    },
    async onSubmit() {
      if (!this.checkInputs()) return;
      // this.loading = true
      // if (this.userInfo?.phoneNumber.verification) {
      /* eslint-disable-next-line */
      await this.addCard({phone_number_code: this.info.phoneCode});
      // }
      // else {
      //   const data = await this.bindSMS();
      //   if (!(data instanceof Error)) {
      //     /* eslint-disable-next-line */
      //     await this.addCard({phone_number_code: this.info.phoneCode});
      //   }
      // }
    },
    beforeCheckSms() {
      // console.log(this.$info.$_authMode, this.$_phoneNumber, this.)
      if (!this.userInfo.phoneNumber.verification && !this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
        return false;
      } else {
        this.checkSMS();
      }
    },

    async checkSMS() {
      if (this.userInfo?.phoneNumber.verification) {
        this.showWaitTime();
        //已認證
        const data = await phoneVerification.read<IMessage>({});
        if (data instanceof Error) {
          ElMessage({
            type: 'error',
            message: data.message || 'fail'
          });
        } else {
          ElMessage({
            type: 'success',
            message: data.message || 'success'
          });
        }
        return data;
      } else {
        //未認證
        const data = await this.addSMS();
        return data;
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          console.log('response: ', response);
          // this.$ElMessage.success(info.message)
          ElMessage({
            type: 'success',
            message: response?.message || 'success'
          });
          // this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$ElMessage.fail(error.message)
          ElMessage({
            type: 'error',
            message: error.message || 'fail'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },

    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    verSms() {
      return this.info.phoneDisabled;
    },
    phoneNumber() {
      return this.$refs.tel ? this.$refs.tel.info.phoneObject.number.e164 : '';
    }
  }
})
export default class AddBankCard extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  cardList: ICard[] = [];
  control: {
    showBanks: boolean;
    columns: IBankCard[];
    bankId: string;
    bankCode: string;
  } = {
    showBanks: false,
    columns: [],
    bankId: '', // 銀行 ID
    bankCode: ''
  };
  info = {
    phoneNumber: '',
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: '',
    phoneCodeMsg: '',
    number: '',
    lastName: '',
    firstName: '',
    name: '',
    bankId: '',
    numberErrorMsg: ''
  };
  errors = {
    name: false,
    lastName: false,
    firstName: false,
    number: false,
    phoneNumber: false,
    phoneCode: false,
    bank: false
  };
  get stopValid() {
    switch (this.config.dollarSign) {
      case '₫':
      case '¥':
        return !this.info.number || !this.info.bankId || !this.info.name;
      default:
        return (
          // !this.info.number || !this.info.bankId
          !this.info.number || !this.info.bankId || !this.info.lastName || !this.info.firstName
        );
    }
  }
  async mounted() {
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    const [res1, res2] = await Promise.all([
      userCard.info<ICardListResult>(),
      userCard.infoSettings({name: ['user_binding_card_time']})
    ]);
    console.log('res1, res2: ', res1, res2);
    if (!(res1 instanceof Error)) {
      const list = res1.list.map(v => {
        v.text = `${v.bankName}`;
        v.dom = `<span class="icon bank-img bank__icon-${v.bankCode}"></span><span>${v.bankName}</span>`;
        return v;
      });
      this.control.columns = [...this.control.columns, ...list];
    }
  }
  handeValidate(val: {isValid: boolean}) {
    this.errors.phoneNumber = !val.isValid;
  }
  checkRules(value: any, key: string) {
    switch (key) {
      case 'name':
        this.errors.name = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'lastName':
        this.errors.lastName = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'firstName':
        this.errors.firstName = !value || !/^[^@#$%^&*()_+=]{1,20}$/.test(value);
        break;
      case 'number':
        this.errors.number = !value || !/^\d{8,19}$/.test(value);
        break;
      case 'phoneCode':
        this.errors.phoneCode = !value;
        break;
    }
  }
  clearError() {
    console.log('clearError');
  }

  async addCard() {
    const {name, ...other} = this.info;
    const {dollarSign} = this.config;
    const bank = this.control.columns.filter(v => v.bankId === other.bankId)[0];
    const para = {
      /* eslint-disable-next-line */
      bank_id: bank.bankId, // 銀行 ID
      /* eslint-disable-next-line */
      bank_code: bank.bankCode, // 銀行代碼
      province: '', // 省份 ID
      city: '', // 城市 ID
      branch: '', // 開戶支行
      number: other.number, // 銀行帳號
      /* eslint-disable-next-line */
      confirm_number: other.number
    };
    let params = {};

    if (dollarSign === '₫') {
      params = {...params, name};
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = {...params, first_name: other.firstName, last_name: other.lastName};
    }
    params = {...para, ...params};
    console.log(params);
    const data = await this.bindCard(params);
    console.log(`debug: onSubmit -> data`, data);
    if (data instanceof Error) {
      ElMessage.error(data.message || 'fail');
      return false;
    } else {
      ElNotification({
        message: this.$t('tip_add_success')
      });
      this.info = {
        phoneNumber: '',
        phoneNumberMsg: '',
        phoneDisabled: true,
        phoneWaitTime: 300,
        phoneErrorWaitTime: 15,
        verPhoneWaitTime: 0,
        smsInterval: '',
        phoneCode: '',
        phone: '',
        phoneCodeMsg: '',
        number: '',
        lastName: '',
        firstName: '',
        name: '',
        bankId: '',
        numberErrorMsg: ''
      };
      setTimeout(() => {
        this.$router.back();
      }, 1000);
    }
    return data;
  }
  async bindSMS() {
    // let number = phoneUtil.parseAndKeepRawInput(this.$info.$_phone, this.$info.$_countryCode)
    const data = await phoneVerification.updated({
      // phone_number: phoneUtil.format(number, PNF.E164),
      code: this.info.phoneCode
    });
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message:
          this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail'
      });
      // console.log('info.message', info.message)
      // let message = this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed')
      // this.$ElMessage.fail(message || 'fail')
      return false;
    } else {
      ElMessage({
        type: 'error',
        message:
          this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
          'fail'
      });
      //修改狀態
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    return data;
  }
  async bindCard(params: object) {
    const data = await userCard.created(params);
    return data;
  }
  goToLink(name: string) {
    this.$router.push({name});
  }
}
</script>
<style lang="scss" scoped>
.password-container {
  font-size: 16px;
  font-weight: 400;
  margin: 10px;
}
.form__field {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .form__title {
    padding-top: 5px;
    text-align: start;
    min-width: 100px;
  }
}

[lang='en'] {
  .form__field .form__title {
    min-width: 180px;
  }
}
.c_input--longest {
  width: 100%;
  min-width: 270px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &[disabled] {
    cursor: not-allowed;
    background-color: #e8e8e8;
    color: #999999;
  }
}
.telinput {
  width: 755px !important;
  .tel-input {
    width: 330px !important;
  }
}
.c_input--reglong {
  width: 365px !important;
  background-color: #f5f5f5;
  color: #cccccc;
  &:focus {
    border-color: transparent;
    color: #666666;
  }
  &:valid {
    border-color: transparent;
    color: #666666;
  }
}
.c_input--reglong :deep(.el-input) {
  min-width: 365px;
  background-color: #f5f5f5;
  color: #cccccc;
  &:focus {
    border-color: transparent;
    color: #666666;
  }
  &:valid {
    border-color: transparent;
    color: #666666;
  }
}
.list-detail__form :deep(input) {
  padding: 0 10px;
}
.c_input--longest :deep(.el-input) {
  min-width: 310px;
  background-color: #f5f5f5;
  color: #cccccc;
  &:focus {
    border-color: transparent;
    color: #666666;
  }
  &:valid {
    border-color: transparent;
    color: #666666;
  }
}
.userbtn-bg--submit {
  background-color: #1f55f0;
  color: #ffffff;
}
.u_w--100p {
  width: 100%;
}
.u_m--t20 {
  margin-top: 20px;
}
.list-detail__form .form__field > div:not(.form__title) {
  width: inherit;
}
.text-color {
  color: #999999;
  font-weight: 900;
}
.j_text--blue {
  color: #0047ab !important;
}
.list-detail__form .c_tooltip {
  line-height: normal;
  font-size: 14px;
}
.list-detail__form {
  padding: 0;
}
.el-button--info {
  color: $cf;
  background-color: $bg;
  border-color: $bg;
  padding: 10px 20px;
  font-size: 1em;
  &.is-disabled {
    background-color: #c5d5ff;
    border-color: #c5d5ff;
    color: #999999;
  }
}
.el-button--info:hover {
  background-color: darken($bg, 10%);
  border-color: darken($bg, 10%);
  &.is-disabled {
    background-color: #c5d5ff;
    border-color: #c5d5ff;
    color: #999999;
  }
}
.el-button.is-round {
  border-radius: 5px;
}
.bg--border {
  background-color: #c4c4c4;
  height: 2px;
  margin: 2vh 0;
}
.userbtn-bg--submit:hover {
  opacity: 0.5;
}
.userbtn-bg--submit:disabled {
  background-color: #e8e8e8;
  background-image: linear-gradient(to left, #e8e8e8, #e8e8e8);
  color: $c9;
  cursor: not-allowed;
}
.u_m--t50 {
  margin-top: 50px;
}
.u_m--b50 {
  margin-bottom: 50px;
}
.c_button--huge :hover {
  opacity: 0.5;
}
.border::before {
  content: '';
  position: absolute;
  height: 1px;
  background: #c9c9c9;
  top: 32.4%;
  right: 4.9%;
  width: 70.5%;
}
.list__header {
  padding: 0;
}
//AIA title
.static-container-header__title {
  color: #000;
  font-size: 28px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
}
.static-container-header__left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}
.static-container-header__right {
  color: $bg;
  font-size: 14px;
}
.noneinput {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}
.note {
  :deep(.tel-input) {
    border: 1px solid $rc;
  }
  .c_input--reglong {
    border: 1px solid $rc;
  }
}
.hasinput :deep(.tel-input) {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0;
}
.hasinput {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0;
}
.inboder :deep(.el-input__inner) {
  border: none;
  background-color: #f5f5f5;
  color: #cccccc;
  &:focus {
    border-color: transparent;
    color: #666666;
  }
  &:valid {
    border-color: transparent;
    color: #666666;
  }
}
.clearpadding {
  padding: 0 !important;
}

.clearpadding {
  padding: 0;
}
.u_c--pointer {
  cursor: pointer;
}
.u_t-transfrom-cap {
  text-transform: capitalize;
}
.u_m--l10 {
  margin-left: 10px;
}
</style>
